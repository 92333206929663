import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import { Button, ButtonGroup, Collapse, Text } from "@chakra-ui/react";

// import { getType, random } from "../helpers";

import Layout from "../components/Layout";
import ImageMeta from "../components/ImageMeta";
import ImageZoom from "../components/ImageZoom";
import Gallery from "../components/Gallery";

import { getTextColor } from "../helpers";
import documentToReactComponentsCustom from "../ctfTextToReact";

import { WorkI } from "../interfaces";

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  color: #fff;

  @media (min-width: 420px) {
    width: 75%;
  }
`;

const Work = ({
  data: {
    contentfulWork: {
      node_locale,
      title,
      slug,
      backgroundColor,
      textColor = "auto",
      date,
      description,
      wkv,
      media,
      width,
      height,
      depth,
      technique,
      tags,
      institution,
      location,
      text,
      textLong,
      imageDescription,
      thirdPartyDescription,
      makingOfVideo,
      makingOfMedia,
    },
  },
  location: pageLocation,
  pageContext: { language, localeSwitcher },
}: {
  data: { contentfulWork: WorkI };
  location: { pathname: string };
  pageContext: {
    language: string;
    localeSwitcher: { [key: string]: string };
  };
}) => {
  const [showTextLong, setShowTextLong] = React.useState(false);
  const [showThirdPartyDescription, setShowThirdPartyDescription] =
    React.useState(false);

  const color = getTextColor(textColor, backgroundColor);

  const ImageShadow = styled.div`
    box-shadow: #000 2px 2px 16px -6px;
  `;

  return (
    <Layout
      title={title}
      description={description}
      locale={node_locale}
      location={pageLocation}
      backgroundColor={backgroundColor}
      limitWidth
      localeSwitcher={localeSwitcher}
    >
      <ImageWrapper>
        <ImageShadow>
          <ImageZoom
            image={getImage(media[0]?.gatsbyImageData)!}
            alt={title}
            description={media[0].description}
            style={{ maxHeight: "80vh" }}
          />
        </ImageShadow>
        <ImageMeta
          title={title}
          date={date}
          width={width}
          height={height}
          depth={depth}
          technique={technique}
          wkv={wkv}
          institution={institution}
          location={location}
          color={color}
          textShadow
        />
      </ImageWrapper>

      {text?.raw && (
        <div style={{ margin: "1.5em 0", color }}>
          {documentToReactComponentsCustom(text)}
        </div>
      )}

      {(textLong?.raw || thirdPartyDescription?.raw) && (
        <ButtonGroup>
          {textLong?.raw && (
            <Button
              onClick={() => setShowTextLong(!showTextLong)}
              backgroundColor="#ffffff40"
              borderRadius={0}
            >
              {language == "de"
                ? showTextLong
                  ? "Weniger anzeigen"
                  : "Mehr anzeigen"
                : showTextLong
                ? "Show less"
                : "Show more"}
            </Button>
          )}
          {thirdPartyDescription?.raw && (
            <Button
              onClick={() =>
                setShowThirdPartyDescription(!showThirdPartyDescription)
              }
              backgroundColor="#ffffff40"
              borderRadius={0}
            >
              {language == "de"
                ? "Andere über das Werk" +
                  (showThirdPartyDescription && "ausblenden")
                : showThirdPartyDescription
                ? "Show other's descriptions"
                : "Hide other's description"}
            </Button>
          )}
        </ButtonGroup>
      )}

      {textLong?.raw && (
        <Collapse in={showTextLong}>
          <div style={{ margin: "1.5em 0", color }}>
            {documentToReactComponentsCustom(textLong)}
          </div>
        </Collapse>
      )}

      {thirdPartyDescription?.raw && (
        <Collapse in={showThirdPartyDescription}>
          <div style={{ margin: "1.5em 0", color }}>
            {documentToReactComponentsCustom(thirdPartyDescription)}
          </div>
        </Collapse>
      )}

      {media?.length && (
        <Gallery media={media.slice(1)} style={{ margin: "1em auto" }} />
      )}

      {(makingOfMedia?.length || makingOfVideo?.url) && (
        <Text as="h2" marginTop="2.5em" color={color}>
          Making of
        </Text>
      )}

      {makingOfVideo?.url && (
        <video
          src={makingOfVideo.url}
          controls
          style={{ width: "100%", margin: "1em auto" }}
        />
      )}

      {makingOfMedia?.length && (
        <Gallery
          media={makingOfMedia.slice(1)}
          style={{ margin: "1em auto" }}
        />
      )}

      {imageDescription && (
        <Text marginTop="1.5em" marginBottom="1.5em" color={color}>
          {imageDescription}
        </Text>
      )}
    </Layout>
  );
};

export default Work;

export const pageQuery = graphql`
  query WorkQuery($contentful_id: String!, $language: String!) {
    contentfulWork(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $language }
    ) {
      ...ContentfulWorkFragment
    }
  }
`;
