import * as React from "react";
import styled from "@emotion/styled";
import { Heading, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import dayjs from "dayjs";

const ImageMeta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  text-align: left;
  gap: 1em;

  p {
    margin: 0;
    font-size: 1.22em;
    line-height: 1.1;
  }
`;

export default ({
  title,
  date,
  technique,
  width,
  height,
  depth,
  wkv,
  institution,
  location,
  color = "#000",
  textShadow = false,
  linkTo,
}: {
  title: string;
  date: string;
  technique: string;
  width: number;
  height: number;
  depth?: number;
  wkv: string;
  institution?: string;
  location?: string;
  color?: string;
  textShadow?: boolean;
  linkTo?: string;
}) => {
  const StyledHeading = styled(Heading)`
    text-shadow: 0px 0px 0.12em
      rgb(
        ${color === "#000" || color === "#000000" ? "255 255 255" : "0 0 0"} /
          75%
      );
    ${!textShadow && "text-shadow: inherit;"}
    span {
      /* font-family: "Rubik", sans-serif; */
      font-size: 0.78em;
      font-weight: 400;
    }
  `;

  return (
    <ImageMeta as={linkTo ? Link : "div"} {...(linkTo ? { to: linkTo } : {})}>
      <div>
        <StyledHeading padding="0.5em 0" lineHeight={1}>
          {title}
          <span>, {dayjs(date).format("YYYY")}</span>
        </StyledHeading>
        <Text textAlign="left" textColor={color}>
          {technique}
          <br />
          {width &&
            height &&
            `${height.toLocaleString()} x ${width.toLocaleString()}`}
          {depth && ` x ${depth.toLocaleString()}`}
          {(width || height || depth) && " cm"}
        </Text>
      </div>
      <Text textColor={color}>
        {(institution || location) && (
          <>
            {institution && (
              <>
                {institution}
                <br />
              </>
            )}
            {location}
            <br />
          </>
        )}
        WKV: {wkv}
      </Text>
    </ImageMeta>
  );
};
