import * as React from "react";
import { getImage } from "gatsby-plugin-image";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { MediaI } from "../interfaces";
import ImageZoom from "./ImageZoom";

const Gallery = ({
  media,
  style = {},
}: {
  media: MediaI[];
  style?: React.CSSProperties;
}) => {
  if (!media) return null;

  let config;
  switch (media.length) {
    case 1:
      config = { columnsCountBreakPoints: { 100: 1 }, style: { width: "66%" } };
      break;
    case 2:
      config = { columnsCountBreakPoints: { 100: 1, 200: 2 } };
      break;
    default:
      config = { columnsCountBreakPoints: { 100: 1, 200: 2, 750: 3 } };
      break;
  }
  config = { ...config, style: { ...config?.style, ...style } };

  return (
    <>
      <ResponsiveMasonry {...config}>
        <Masonry gutter="1rem">
          {media?.map(({ gatsbyImageData, description, title }, i) => (
            <ImageZoom
              key={i}
              image={getImage(gatsbyImageData)!}
              description={description}
              alt={title!}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};

export default Gallery;
